.invisible {
    opacity: 0;
    position: absolute;
    width: 0px;
    height: 0px;
}

.wrap {
    padding-top: 66.6%;
    position: relative;
    max-width: 100%;
}